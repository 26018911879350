import React from "react"
import Start from "../components/Start"
import Exercise from "../components/Exercise"
import Main from "../components/Main"
// import BackgroundLoader from "../components/BackgroundLoader"
import { useParams } from "react-router-dom"

const Routine = () => {
  let { id } = useParams()
  return (
    <React.Fragment>
      <Main>
        <Start slug={id} />
        <Exercise />
      </Main>
      {/*<BackgroundLoader />*/}
    </React.Fragment>
  )
}

export default Routine

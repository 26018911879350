import React from "react"
import UIfx from "uifx"
import TimerMachine from "react-timer-machine"
import { useStateValue } from "../state/state"
import endSound from "../sounds/end3.mp3"
import nextSound from "../sounds/next3.mp3"

const end = new UIfx(endSound, {
  volume: 1, // number between 0.0 ~ 1.0
  throttleMs: 20
})
const next = new UIfx(nextSound, {
  volume: 1, // number between 0.0 ~ 1.0
  throttleMs: 20
})

const Timer = ({ duration }) => {
  const [
    { isRunning, isPaused, isStopped, isExercise, isRest },
    dispatch
  ] = useStateValue()
  const handleOnStart = () => {
    dispatch({
      type: "onStart"
    })
  }
  const handleOnTick = time => {
    dispatch({
      type: "onTick",
      time: time
    })
  }
  const handleOnComplete = () => {
    if (isExercise) {
      end.play()
    } else if (isRest) {
      next.play()
    }

    dispatch({
      type: "onComplete"
    })
  }

  return (
    <div className='timer'>
      <div
        className={`timer__fixed ${isStopped ? "" : "timer__fixed--hidden"}`}
      >
        {duration}
      </div>
      <div
        className={`timer__running ${
          isStopped ? "timer__running--hidden" : ""
        }`}
      >
        <TimerMachine
          timeStart={duration * 1000}
          timeEnd={0}
          started={isRunning}
          paused={isPaused}
          countdown={true}
          interval={100}
          formatTimer={(time, ms) => {
            // ((ms % 60000) / 1000).toFixed(0)
            return (ms / 1000).toFixed(0)
          }}
          onStart={handleOnStart}
          onTick={handleOnTick}
          onComplete={handleOnComplete}
        />
      </div>
    </div>
  )
}

export default Timer

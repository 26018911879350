import React from "react"

const ArrowRightIcon = () => {
  return (
    <svg
      className='icon icon--arrow-right'
      width='56'
      height='30'
      viewBox='0 0 56 30'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.6863 0.858248L55.4142 13.5862C56.1953 14.3672 56.1953 15.6335 55.4142 16.4146L42.6863 29.1425C41.9052 29.9236 40.6389 29.9236 39.8579 29.1425C39.0768 28.3615 39.0768 27.0951 39.8579 26.3141L49.1716 17.0004H2C0.89543 17.0004 0 16.105 0 15.0004C0 13.8958 0.89543 13.0004 2 13.0004H49.1716L39.8579 3.68667C39.0768 2.90563 39.0768 1.6393 39.8579 0.858248C40.6389 0.0771987 41.9052 0.0771987 42.6863 0.858248Z'
      />
    </svg>
  )
}

export default ArrowRightIcon

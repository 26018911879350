import React from "react"

const NextExercise = ({ exercise }) => {
  const isVisible = exercise !== undefined
  return (
    isVisible && (
      <div className={"up-next"}>
        <small className={"up-next__label small"}>Up next:</small>
        <h2 className={"up-next__exercise"}>{exercise.name}</h2>
      </div>
    )
  )
}

export default NextExercise

const routines = [
  // {
  //   slug: "dummy-routine",
  //   title: "Dummy Routine",
  //   description: "Dummy short routine to test the app.",
  //   routine: [
  //     { name: "First exercise", duration: 5 },
  //     { name: "Second exercise", duration: 5 },
  //     { name: "Third exercise", duration: 5 },
  //     { name: "Fourth exercise", duration: 5 },
  //     { name: "REST", duration: 10, rest: true },
  //     { name: "First exercise", duration: 5 },
  //     { name: "Second exercise", duration: 5 },
  //     { name: "Third exercise", duration: 5 },
  //     { name: "Fourth exercise", duration: 5 }
  //   ]
  // },
  {
    slug: "upper-body-rings",
    title: "Full upper body with rings",
    description: "Half an hour full upper body rings workout",
    routine: [
      { name: "Bent-arm Hang (1.2)", duration: 10 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Bent-arm Hang", duration: 10 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Bent-arm Hang", duration: 10 },
      { name: "Long rest", duration: 60, rest: true },
      { name: "Bent-arm Tuck", duration: 15 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Bent-arm Tuck", duration: 15 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Bent-arm Tuck", duration: 15 },
      { name: "Lower rings to 4.1", duration: 60, rest: true },
      { name: "Streight Arm Facing Up Tuck", duration: 10 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Streight Arm Facing Up Tuck", duration: 10 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Full Rotation Hold", duration: 20 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Full Rotation Hold", duration: 20 },
      { name: "Lower rings to 6.2", duration: 60, rest: true },
      { name: "Streight body support hold", duration: 15 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Streight body support hold", duration: 15 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Streight body support hold", duration: 15 },
      { name: "Lower rings to 6.3", duration: 60, rest: true },
      { name: "Ring Rows - Streight (10x)", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Ring Rows - Bent Legs (10x)", duration: 30 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Tuck Planche", duration: 10 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Tuck Planche", duration: 10 },
      { name: "Lower rings to 9.2", duration: 60, rest: true },
      { name: "Dip Negatives - Streight Legs (5x)", duration: 30 },
      { name: "Dip Negatives (5x)", duration: 30 },
      { name: "Dip Negatives (5x)", duration: 30 },
      { name: "Lower rings to 11.3", duration: 60, rest: true },
      { name: "Plank Hold", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Ab Rollout (5x)", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Plank Leg Drives", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Push up (5x)", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Push up (5x)", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Push up (5x)", duration: 20 },
      { name: "Remove rings....", duration: 60, rest: true },
      { name: "Wide Pull-Up (5x)", duration: 45 },
      { name: "Chin Up (5x)", duration: 45 },
      { name: "Parallel Pull-Up (5x)", duration: 45 }
    ]
  },
  {
    slug: "full-core",
    title: "Full core",
    description: "Half an hour full core workout.",
    routine: [
      { name: "Yoga ball hold crunches - 15", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Yoga ball hold crunches - 15", duration: 30 },
      { name: "Sit on the ball...", duration: 20, rest: true },
      { name: "Yoga ball facing wall - 15", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Yoga ball facing wall - 15", duration: 30 },
      { name: "Face the ball...", duration: 20, rest: true },
      { name: "Yoga ball facing down - 15", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Yoga ball facing down - 15", duration: 30 },
      { name: "Lay on the mat...", duration: 30, rest: true },
      { name: "Hollow Body", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Hollow Body", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Hollow Body Rocks", duration: 30 },
      { name: "Face down...", duration: 20, rest: true },
      { name: "Arch Body", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Arch Body", duration: 30 },
      { name: "Side Plank - Left", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Side Plank - Left", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Side Plank - Left", duration: 20 },
      { name: "Change side...", duration: 20, rest: true },
      { name: "Side Plank - Right", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Side Plank - Right", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Side Plank - Right", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Plank walk - 3", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Plank walk - 3", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Plank step ups", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Plank step ups", duration: 20 },
      { name: "Face up...", duration: 30, rest: true },
      { name: "Bycicle Crunches", duration: 20 },
      { name: "Rest", duration: 10, rest: true },
      { name: "Bycicle Crunches", duration: 20 },
      { name: "Rest", duration: 30, rest: true },
      { name: "Floor wipers", duration: 60 },
      { name: "Rest", duration: 10, rest: true },
      { name: "Floor wipers", duration: 60 },
      { name: "Prepare...", duration: 10, rest: true },
      { name: "Bridge", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Bridge", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Heel Touches", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Bridge", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Heel Touches", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Bridge", duration: 20 },
      { name: "Face down...", duration: 10, rest: true },
      { name: "Dog Bird - Right Arm", duration: 20 },
      { name: "Dog Bird - Left Arm", duration: 20 },
      { name: "Dog Bird - Right Arm", duration: 20 },
      { name: "Dog Bird - Left Arm", duration: 20 },
      { name: "Dog Bird - Right Arm", duration: 20 },
      { name: "Dog Bird - Left Arm", duration: 20 }
    ]
  },
  {
    slug: "full-legs",
    title: "Full Legs",
    description: "Half an hour full lower body workout.",
    routine: [
      { name: "Knee Rises - Left", duration: 20 },
      { name: "Heel to Butt - Left", duration: 20 },
      { name: "Knee Rises - Right", duration: 20 },
      { name: "Heel to Butt - Right", duration: 20 },
      { name: "Light Squat", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Knee Push - Left (10x)", duration: 40 },
      { name: "Knee Push - Right (10x)", duration: 40 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Knee Push Hold - Left", duration: 20 },
      { name: "Knee Push Hold - Right", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Sky Reaches - Left (10x)", duration: 30 },
      { name: "Sky Reaches - Right (10x)", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Sky Reaches Hold (Extend arm) - Left", duration: 20 },
      { name: "Sky Reaches Hold (Extend arm) - Right", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Budha Prayers (10x)", duration: 45 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Squat Bows (10x)", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Squat Bows Hold", duration: 20 },
      { name: "Get the ball...", duration: 30, rest: true },
      { name: "Swiss Ball Squeeze - 2s Pace (15x)", duration: 30 },
      { name: "Rest...", duration: 60, rest: true },
      { name: "Cossack Squats - 2s Pace (10x)", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Temp Squats - 4s Pace (10x)", duration: 60 },
      { name: "Pull up a chair...", duration: 20, rest: true },
      { name: "Bulgarian Split Squats - Left - 4s Pace (10x)", duration: 60 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Bulgarian Split Squats - Right - 4s Pace (10x)", duration: 60 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Single Leg Hip Thrusts - Left - 2s Hold (10x)", duration: 30 },
      { name: "Single Leg Hip Thrusts - Right - 2s Hold (10x)", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Pistol Squats - Left - 2s Pace (5x)", duration: 20 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Pistol Squats - Right - 2s Pace (5x)", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Split Squats - Left - 2s Pace (10x)", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Split Squats - Right - 2s Pace (10x)", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Curtsey Lunges - 2s Pace (20x)", duration: 60 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Calf Raises - Left - 2s Pace (15x)", duration: 30 },
      { name: "Calf Raises - Right - 2s Pace (15x)", duration: 30 }
    ]
  },
  {
    slug: "core-short",
    title: "Core",
    description: "15 Minutes core workout without any equipement.",
    routine: [
      { name: "Plank", duration: 30 },
      { name: "Hip Raise 'Butt ups'", duration: 30 },
      { name: "Heel Touches", duration: 20 },
      { name: "V Sit - Seated Scissor", duration: 30 },
      { name: "Heel Touches Both Hands", duration: 20 },
      { name: "Plank Step Ups", duration: 20 },
      { name: "Plank Walk Out", duration: 20 },
      { name: "Cross Crunch", duration: 20 },
      { name: "Bicycle Crunches", duration: 20 },
      { name: "Plank", duration: 30 },
      { name: "Hip Raise 'Butt ups'", duration: 30 },
      { name: "Heel Touches", duration: 20 },
      { name: "V Sit - Seated Scissor", duration: 30 },
      { name: "Heel Touches Both Hands", duration: 20 },
      { name: "Plank Step Ups", duration: 20 },
      { name: "Plank Walk Out", duration: 20 },
      { name: "Cross Crunch", duration: 20 },
      { name: "Bicycle Crunches", duration: 20 },
      { name: "REST", duration: 60, rest: true },
      { name: "Glute Bridges", duration: 60 },
      { name: "Alternate Bird Dog - Right Arm", duration: 20 },
      { name: "Alternate Bird Dog - Left Arm", duration: 20 },
      { name: "Glute Bridges", duration: 60 },
      { name: "Alternate Bird Dog - Right Arm", duration: 20 },
      { name: "Alternate Bird Dog - Left Arm", duration: 20 }
    ]
  },
  {
    slug: "uper-body",
    title: "Upper body",
    description: "15 Minutes upper body pull-up bar routine.",
    routine: [
      { name: "Wide Pull-Ups (5x)", duration: 45 },
      { name: "Chinups (5x)", duration: 45 },
      { name: "Parallel Pull-Ups (5x)", duration: 45 },
      { name: "Mix Grip Pull-ups - Left (5x)", duration: 45 },
      { name: "Mix Grip Pull-ups - Right (5x)", duration: 45 },
      { name: "Rest...", duration: 45, rest: true },
      { name: "Wide Pull-Ups (5x)", duration: 45 },
      { name: "Chinups (5x)", duration: 45 },
      { name: "Parallel Pull-Ups (5x)", duration: 45 },
      { name: "Mix Grip Pull-ups - Left (5x)", duration: 45 },
      { name: "Mix Grip Pull-ups - Right (5x)", duration: 45 },
      { name: "Rest...", duration: 45, rest: true },
      { name: "Wide Pull-Ups (5x)", duration: 45 },
      { name: "Chinups (5x)", duration: 45 },
      { name: "Parallel Pull-Ups (5x)", duration: 45 },
      { name: "Mix Grip Pull-ups - Left (5x)", duration: 45 },
      { name: "Mix Grip Pull-ups - Right (5x)", duration: 45 }
    ]
  },
  {
    slug: "kettlebell",
    title: "Kettlebell",
    description: "30 Minutes kettlebell routine.",
    routine: [
      { name: "Light Squat", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Knee Push - Left (10x)", duration: 40 },
      { name: "Knee Push - Right (10x)", duration: 40 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Knee Push Hold - Left", duration: 20 },
      { name: "Knee Push Hold - Right", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Sky Reaches - Left (10x)", duration: 30 },
      { name: "Sky Reaches - Right (10x)", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Sky Reaches Hold (Extend arm) - Left", duration: 20 },
      { name: "Sky Reaches Hold (Extend arm) - Right", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Budha Prayers (10x)", duration: 45 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Squat Bows (10x)", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Squat Bows Hold", duration: 20 },
      { name: "Get the kettlebell...", duration: 30, rest: true },
      { name: "Rotations - Left", duration: 30 },
      { name: "Rotations - Right", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Rotations with push", duration: 40 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Suitcase walk - Left", duration: 30 },
      { name: "Suitcase walk - Right", duration: 30 },
      { name: "Suitcase walk - Left", duration: 30 },
      { name: "Suitcase walk - Right", duration: 30 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Halo", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo to hip", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo half kneeling - Right", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo half kneeling - Left", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo kneeling", duration: 30 },
      { name: "Rest...", duration: 60, rest: true },
      { name: "Deadlift", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Two arm clean", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Goblet squat", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Overhead press", duration: 30 },
      { name: "Rest...", duration: 45, rest: true },
      { name: "Swing", duration: 30 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Kettlebell oblique - Left", duration: 30 },
      { name: "Kettlebell oblique - Right", duration: 30 },
      { name: "Lay down...", duration: 60, rest: true },
      { name: "One arm chest press - Right", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "One arm chest press - Left", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Two arm chest press", duration: 30 },
      { name: "Stand up...", duration: 30, rest: true },
      { name: "One arm row - Left", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "One arm row - Right", duration: 30 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Upside down hold - Left", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Upside down hold - Right", duration: 30 }
    ]
  },
  {
    slug: "kettlebell-2x",
    title: "Kettlebell 2x",
    description: "1 Hour kettlebell routine.",
    routine: [
      { name: "Knee Rises - Left", duration: 20 },
      { name: "Heel to Butt - Left", duration: 20 },
      { name: "Knee Rises - Right", duration: 20 },
      { name: "Heel to Butt - Right", duration: 20 },
      { name: "Light Squat", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Knee Push - Left (10x)", duration: 40 },
      { name: "Knee Push - Right (10x)", duration: 40 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Knee Push Hold - Left", duration: 20 },
      { name: "Knee Push Hold - Right", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Sky Reaches - Left (10x)", duration: 30 },
      { name: "Sky Reaches - Right (10x)", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Sky Reaches Hold (Extend arm) - Left", duration: 20 },
      { name: "Sky Reaches Hold (Extend arm) - Right", duration: 20 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Budha Prayers (10x)", duration: 45 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Squat Bows (10x)", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Squat Bows Hold", duration: 20 },
      { name: "Get the kettlebell...", duration: 30, rest: true },
      { name: "Rotations - Left", duration: 30 },
      { name: "Rotations - Right", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Rotations with push", duration: 40 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Suitcase walk - Left", duration: 30 },
      { name: "Suitcase walk - Right", duration: 30 },
      { name: "Suitcase walk - Left", duration: 30 },
      { name: "Suitcase walk - Right", duration: 30 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Halo", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo to hip", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo half kneeling - Right", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo half kneeling - Left", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo kneeling", duration: 30 },
      { name: "Rest...", duration: 60, rest: true },
      { name: "Deadlift", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Two arm clean", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Goblet squat", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Overhead press", duration: 30 },
      { name: "Lay down...", duration: 60, rest: true },
      { name: "One arm chest press - Right", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "One arm chest press - Left", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Two arm chest press - Left", duration: 30 },
      { name: "Stand up...", duration: 30, rest: true },
      { name: "One arm row - Left", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "One arm row - Right", duration: 30 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Upside down hold - Left", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Upside down hold - Right", duration: 30 },
      { name: "Rest...", duration: 60, rest: true },
      { name: "Rotations - Left", duration: 30 },
      { name: "Rotations - Right", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Rotations with push", duration: 40 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Suitcase walk - Left", duration: 30 },
      { name: "Suitcase walk - Right", duration: 30 },
      { name: "Suitcase walk - Left", duration: 30 },
      { name: "Suitcase walk - Right", duration: 30 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Halo", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo to hip", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo half kneeling - Right", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo half kneeling - Left", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Halo kneeling", duration: 30 },
      { name: "Rest...", duration: 60, rest: true },
      { name: "Deadlift", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Two arm clean", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Goblet squat", duration: 30 },
      { name: "Rest...", duration: 20, rest: true },
      { name: "Overhead press", duration: 30 },
      { name: "Lay down...", duration: 60, rest: true },
      { name: "One arm chest press - Right", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "One arm chest press - Left", duration: 30 },
      { name: "Rest...", duration: 10, rest: true },
      { name: "Two arm chest press - Left", duration: 30 },
      { name: "Stand up...", duration: 30, rest: true },
      { name: "One arm row - Left", duration: 30 },
      { name: "One arm row - Right", duration: 30 },
      { name: "Rest...", duration: 30, rest: true },
      { name: "Upside down hold - Left", duration: 30 },
      { name: "Upside down hold - Right", duration: 30 }
    ]
  }
]

export default routines

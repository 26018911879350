import routines from "../routines/routines"
import createFullRoutine from "../utils/createFullRoutine"

const selectedRoutine = routines[0].routine

const routine = createFullRoutine({
  routine: selectedRoutine,
  initialPreparation: 5,
  restDuration: 3
})

const initialState = {
  currentFullRoutine: routine,
  currentStep: routine[0],
  currentExerciseProgress: 0,
  nextExercise: routine[1],
  isStart: true,
  isExercise: false,
  isRest: false,
  isSetupTime: true,
  isLastStep: false,
  isRunning: false,
  isPaused: true,
  isStopped: true,
  workoutFinished: false
}

export default initialState

const createFullRoutine = ({ routine, initialPreparation, restDuration }) => {
  const newRoutine = routine
    .map((item, i, array) => {
      const isLastStep = i === array.length - 1
      const isSecondToLast = i === array.length - 2
      const isRest = item.rest
      const nextIsRest = isLastStep ? false : array[i + 1].rest === true

      let itemsToAdd
      if (isSecondToLast) {
        if (isRest) {
          itemsToAdd = {
            ...item,
            isLastStep: false,
            isSecondToLast: false,
            rest: true,
            isSetupTime: false
          }
        } else {
          if (!nextIsRest) {
            itemsToAdd = [
              {
                ...item,
                isLastStep: false,
                isSecondToLast: false,
                rest: false,
                isSetupTime: false
              },
              {
                name: "Prepare...",
                duration: restDuration,
                isLastStep: false,
                isSecondToLast: false,
                rest: true,
                isSetupTime: false
              }
            ]
          } else {
            itemsToAdd = {
              ...item,
              isLastStep: false,
              isSecondToLast: false,
              rest: false,
              isSetupTime: false
            }
          }
        }
      } else if (isLastStep) {
        if (isRest) {
          itemsToAdd = {
            ...item,
            isLastStep: false,
            isSecondToLast: true,
            rest: true,
            isSetupTime: false
          }
        } else {
          itemsToAdd = {
            ...item,
            isLastStep: false,
            isSecondToLast: true,
            rest: false,
            isSetupTime: false
          }
        }
      } else if (isRest) {
        itemsToAdd = {
          ...item,
          isLastStep: false,
          isSecondToLast: false,
          rest: true,
          isSetupTime: false
        }
      } else {
        if (!nextIsRest) {
          itemsToAdd = [
            {
              ...item,
              isLastStep: false,
              isSecondToLast: false,
              rest: false,
              isSetupTime: false
            },
            {
              name: "Prepare...",
              duration: restDuration,
              isLastStep: false,
              isSecondToLast: false,
              rest: true,
              isSetupTime: false
            }
          ]
        } else {
          itemsToAdd = {
            ...item,
            isLastStep: false,
            isSecondToLast: false,
            rest: false,
            isSetupTime: false
          }
        }
      }
      return itemsToAdd
    })
    .flat()

  return [
    {
      name: "Starting in...",
      duration: initialPreparation,
      rest: true,
      isSetupTime: true,
      isLastStep: false,
      isSecondToLast: false
    },
    ...newRoutine,
    {
      name: "That's it, well done...",
      duration: 20,
      rest: true,
      isSetupTime: false,
      isLastStep: true,
      isSecondToLast: false
    }
  ].map((item, i) => {
    return { ...item, id: i }
  })
}

export default createFullRoutine

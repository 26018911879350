import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import Actions from "./Actions"
import Button from "./Button"
import PlayIcon from "../icons/PlayIcon"
import { useStateValue } from "../state/state"
import formatSecondsToMinutesAndSeconds from "../utils/formatSecondsToMinutesAndSeconds"
import _uniqueId from "lodash/uniqueId"
import routines from "../routines/routines"
import CustomScroll from "react-custom-scroll"
import ReactTooltip from "react-tooltip"
import createFullRoutine from "../utils/createFullRoutine"

const Start = slug => {
  const [{ isStart }, dispatch] = useStateValue()
  const startIsHidden = !isStart
  const routineIndex = routines.findIndex(o => o.slug === slug.slug)
  const currentOriginalRoutine = routines[routineIndex].routine
  const currentRoutineTitle = routines[routineIndex].title

  const currentFullRoutine = createFullRoutine({
    routine: currentOriginalRoutine,
    initialPreparation: 10,
    restDuration: 5
  })
  const fullDuration = Math.ceil(
    currentFullRoutine.reduce((acc, curr) => acc + curr.duration, 0) / 60
  )

  const handleOnClick = () => {
    dispatch({
      type: "startTimer"
    })
  }

  useEffect(() => {
    dispatch({
      type: "setCurrentRoutine",
      id: slug.slug
    })
  }, [dispatch, slug])

  return (
    <div
      className={`layout layout--start`}
      style={{ display: startIsHidden ? "none" : "" }}
    >
      <Actions className={"actions actions--top"}>
        <Link className='button button--text' to='/'>
          Back
        </Link>
      </Actions>
      {!startIsHidden && (
        <div className='card'>
          <header className='card__header'>
            <h3>{currentRoutineTitle}</h3>
            <span>{`${fullDuration} Minutes`}</span>
          </header>
          <div className='card__body'>
            <CustomScroll heightRelativeToParent='100%'>
              <ul className='list list--exercises'>
                {currentOriginalRoutine.map(exercise => {
                  return (
                    <li
                      key={`${_uniqueId("workout-")}`}
                      className={`${
                        exercise.rest ? "item item--rest" : "item"
                      }`}
                    >
                      <span className={"item__title"}>{exercise.name}</span>
                      <span className={"item__duration"}>
                        {formatSecondsToMinutesAndSeconds(exercise.duration)}
                      </span>
                    </li>
                  )
                })}
              </ul>
            </CustomScroll>
          </div>
        </div>
      )}

      <Actions className={"actions actions--bottom actions--gradient"}>
        <Button
          dataTip
          dataFor='start-workout'
          type={"main"}
          icon={<PlayIcon />}
          onClick={handleOnClick}
        />
      </Actions>
      <ReactTooltip
        id='start-workout'
        className='tooltip'
        delayShow={0}
        effect='solid'
      >
        <span>Start workout</span>
      </ReactTooltip>
    </div>
  )
}

export default Start

import React from "react"

const PlayIcon = () => {
  return (
    <svg
      className='icon icon--play'
      width='80'
      height='80'
      viewBox='0 0 80 80'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM35.1094 54.9271L53.5039 42.6641C54.6913 41.8725 54.6913 40.1275 53.5038 39.3359L35.1094 27.0729C33.7803 26.1869 32 27.1396 32 28.737L32 53.263C32 54.8604 33.7803 55.8131 35.1094 54.9271Z'
      />
    </svg>
  )
}

export default PlayIcon

import React from "react"
import "./App.scss"
import Routine from "./pages/Routine"
import Home from "./pages/Home"
import About from "./pages/About"
import Theme from "./components/Theme"
import { StateProvider } from "./state/state"
import reducer from "./state/reducer"
import initialState from "./state/initialState"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

const App = () => {
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Router>
        <div className='app'>
          <Theme>
            <Switch>
              <Route exact path='/'>
                <Home />
              </Route>
              <Route path='/routine'>
                <Routine />
              </Route>
              <Route path='/about'>
                <About />
              </Route>
              <Route path='/:id' children={<Routine />} />
            </Switch>
          </Theme>
        </div>
      </Router>
    </StateProvider>
  )
}

export default App

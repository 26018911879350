import React from "react"
import routines from "../routines/routines"
import Main from "../components/Main"
import Actions from "../components/Actions"
import { Link } from "react-router-dom"
import { useStateValue } from "../state/state"

const Home = () => {
  const [, dispatch] = useStateValue()

  const handleSelectRoutine = e => {
    dispatch({
      type: "setCurrentRoutine",
      id: e.currentTarget.id
    })
  }

  return (
    <Main>
      <div className={`layout layout--start`}>
        <header className={"header"}>
          <h3>Select workout</h3>
        </header>
        <div>
          <ul className={"list list__home"}>
            {routines.map(item => {
              return (
                <li className={"item item--button"} key={item.slug}>
                  <Link
                    className={"item__link"}
                    onClick={handleSelectRoutine}
                    id={item.slug}
                    to={`/${item.slug}`}
                  >
                    <h3 className={"item__title"}>{item.title}</h3>
                    <p className={"item__description"}>{item.description}</p>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>

        <Actions className={"actions actions--bottom actions--gradient"}>
          <Link className='button button--text' to='/about'>
            About
          </Link>
        </Actions>
      </div>
    </Main>
  )
}

export default Home

import React from "react"

const CurrentExercise = ({ exerciseName }) => {
  return (
    <div className={"current-exercise"}>
      <h1 className={"current-exercise__exercise"}>{exerciseName}</h1>
    </div>
  )
}

export default CurrentExercise

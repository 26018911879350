import React from "react"

const ArrowLeftIcon = () => {
  return (
    <svg
      className='icon icon--arrow-left'
      width='56'
      height='30'
      viewBox='0 0 56 30'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3137 29.1417L0.585786 16.4138C-0.195262 15.6328 -0.195262 14.3664 0.585786 13.5854L13.3137 0.857477C14.0948 0.0764286 15.3611 0.0764287 16.1421 0.857478C16.9232 1.63853 16.9232 2.90486 16.1421 3.68591L6.82843 12.9996L54 12.9996C55.1046 12.9996 56 13.895 56 14.9996C56 16.1042 55.1046 16.9996 54 16.9996L6.82843 16.9996L16.1421 26.3133C16.9232 27.0944 16.9232 28.3607 16.1421 29.1417C15.3611 29.9228 14.0948 29.9228 13.3137 29.1417Z'
      />
    </svg>
  )
}

export default ArrowLeftIcon

import React from "react"
import { useStateValue } from "../state/state"

const Theme = ({ children }) => {
  const [
    {
      // currentFullRoutine,
      isStart,
      isExercise,
      isRest,
      isSetupTime,
      isLastStep
      // isRunning,
      // isPaused,
      // isStopped
    }
    // dispatch
  ] = useStateValue()

  // const themes = [
  //   "light",
  //   "dark",
  //   "exercise--active",
  //   "rest--active",
  //   "starting--active",
  //   "final--active"
  // ]
  let classNames = []

  if (isStart) {
    classNames = ["light"]
  } else if (isSetupTime) {
    classNames = ["starting--active"]
  } else if (isLastStep) {
    classNames = ["final--active"]
  } else if (isExercise) {
    classNames = ["exercise--active"]
  } else if (isRest) {
    classNames = ["rest--active"]
  } else {
    classNames = ["light is-page"]
  }

  return <div className={`theme theme--${classNames}`}>{children}</div>
}

export default Theme

import routines from "../routines/routines"
import createFullRoutine from "../utils/createFullRoutine"
const getCurrentId = (routine, currentExercise) => {
  return routine.findIndex(o => o.id === currentExercise.id)
}

const reducer = (state, action) => {
  switch (action.type) {
    case "setCurrentRoutine": {
      const selectedRoutineSlug = action.id
      const selectedRoutineIndex = routines.findIndex(
        x => x.slug === selectedRoutineSlug
      )
      const selectedRoutine = routines[selectedRoutineIndex]
      const selectedFullRoutine = createFullRoutine({
        routine: selectedRoutine.routine,
        initialPreparation: 10,
        restDuration: 5
      })
      return {
        ...state,
        currentFullRoutine: selectedFullRoutine,
        currentStep: selectedFullRoutine[0],
        currentExerciseProgress: 0,
        nextExercise: selectedFullRoutine[1],
        isStart: true,
        isExercise: false,
        isRest: false,
        isSetupTime: true,
        isLastStep: false,
        isRunning: false,
        isPaused: true,
        isStopped: true,
        workoutFinished: false
      }
    }
    case "onStart": {
      return {
        ...state,
        currentExerciseProgress: 0,
        isRunning: true,
        isPaused: false,
        isStopped: false
      }
    }
    case "onTick": {
      const duration = state.currentStep.duration * 1000
      const currentTick =
        action.time.m * 60 * 1000 + action.time.s * 1000 + action.time.ms
      const currentPercentage = 100 - (currentTick * 100) / duration
      return { ...state, currentExerciseProgress: currentPercentage }
    }
    case "onComplete": {
      const currentId = getCurrentId(
        state.currentFullRoutine,
        state.currentStep
      )
      const isRest = state.currentStep.rest
      const isLast = state.currentStep.isLastStep

      let newCurrentStep
      let newNextExercise
      let newIsLastStep
      let newIsRest
      let newIsExercise
      let isStopped = false
      let isPaused = false
      let isRunning = true
      let workoutFinished = false

      if (!isLast) {
        newCurrentStep = state.currentFullRoutine[currentId + 1]
        newNextExercise = isRest
          ? state.currentFullRoutine[currentId + 3]
          : state.currentFullRoutine[currentId + 2]
      } else {
        newCurrentStep = state.currentStep
        newNextExercise = undefined
        isStopped = true
        isPaused = true
        isRunning = false
        workoutFinished = true
      }
      newIsLastStep = newCurrentStep.isLastStep
      newIsRest = newCurrentStep.rest
      newIsExercise = !newCurrentStep.rest

      return {
        ...state,
        currentStep: newCurrentStep,
        currentExerciseProgress: 0,
        nextExercise: newNextExercise,
        isExercise: newIsExercise,
        isRest: newIsRest,
        isSetupTime: false,
        isLastStep: newIsLastStep,
        isRunning: isRunning,
        isPaused: isPaused,
        isStopped: isStopped,
        isStart: false,
        workoutFinished: workoutFinished
      }
    }
    case "startTimer": {
      // TODO: Go to previous rest if on exercise and at 0%
      // If current is exercise go back one and start
      return {
        ...state,
        isStart: false,
        isRunning: true,
        isPaused: false,
        isStopped: false
      }
    }
    case "pauseTimer": {
      return { ...state, isRunning: true, isPaused: true }
    }
    case "resetWorkout": {
      return {
        ...state,
        currentStep: state.currentFullRoutine[0],
        currentExerciseProgress: 0,
        nextExercise: state.currentFullRoutine[1],
        isStart: true,
        isSetupTime: true,
        isExercise: false,
        isRunning: false,
        isPaused: true,
        isStopped: true
      }
    }
    case "setNextExercise": {
      const currentId = getCurrentId(
        state.currentFullRoutine,
        state.currentStep
      )
      const isRest = state.currentStep.rest
      const isLast = state.currentStep.isLastStep
      const isSecondToLast = state.currentStep.isSecondToLast

      let newCurrentStep
      let newNextExercise
      let newIsLastStep
      let newIsRest
      let newIsExercise

      if (isSecondToLast) {
        newCurrentStep = state.currentFullRoutine[currentId + 1]
        newNextExercise = undefined
      } else if (isLast) {
        newCurrentStep = state.currentStep
        newNextExercise = undefined
      } else if (isRest) {
        newCurrentStep = state.currentFullRoutine[currentId + 1]
        newNextExercise = state.currentFullRoutine[currentId + 3]
      } else {
        newCurrentStep = state.currentFullRoutine[currentId + 2]
        newNextExercise =
          state.currentFullRoutine[currentId + 4] === undefined
            ? undefined
            : state.currentFullRoutine[currentId + 4]
      }
      newIsLastStep = newCurrentStep.isLastStep
      newIsRest = newCurrentStep.rest
      newIsExercise = !newCurrentStep.rest

      return {
        ...state,
        currentStep: newCurrentStep,
        currentExerciseProgress: 0,
        nextExercise: newNextExercise,
        isExercise: newIsExercise,
        isRest: newIsRest,
        isSetupTime: false,
        isLastStep: newIsLastStep,
        isRunning: false,
        isPaused: true,
        isStopped: true
      }
    }
    case "setPreviousExercise": {
      const currentId = getCurrentId(
        state.currentFullRoutine,
        state.currentStep
      )
      const isSetupTime = state.currentStep.isSetupTime
      const isRest = state.currentStep.rest
      const isFirst = isSetupTime
      const isSecond = currentId === 1
      const isLast = state.currentStep.isLastStep

      let newCurrentStep
      let newNextExercise
      let newIsLastStep
      let newIsRest
      let newIsExercise
      let newIsFirst

      if (isLast) {
        newCurrentStep = state.currentFullRoutine[currentId - 1]
        newNextExercise = undefined
      } else if (isSecond) {
        newCurrentStep = state.currentFullRoutine[currentId - 1]
        newNextExercise = state.currentStep
      } else if (isFirst) {
        newCurrentStep = state.currentStep
        newNextExercise = state.nextExercise
      } else if (isRest) {
        newCurrentStep = state.currentFullRoutine[currentId - 1]
        newNextExercise = state.currentFullRoutine[currentId + 1]
      } else {
        newCurrentStep = state.currentFullRoutine[currentId - 2]
        newNextExercise = state.currentStep
      }
      newIsLastStep = newCurrentStep.isLastStep
      newIsRest = newCurrentStep.rest
      newIsExercise = !newCurrentStep.rest
      newIsFirst = currentId === 1 ? true : false

      return {
        ...state,
        currentStep: newCurrentStep,
        currentExerciseProgress: 0, // Percentage
        nextExercise: newNextExercise,
        isStart: false,
        isExercise: newIsExercise,
        isRest: newIsRest,
        isSetupTime: newIsFirst,
        isLastStep: newIsLastStep,
        isRunning: false,
        isPaused: true,
        isStopped: true
      }
    }
    default: {
      return state
    }
  }
}

export default reducer

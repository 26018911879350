import React from "react"

const Button = ({ label, onClick, icon, type, disabled, dataTip, dataFor }) => {
  return (
    <button
      data-tip={dataTip}
      data-for={dataFor}
      onClick={onClick}
      className={`button button--${type}`}
      disabled={disabled}
    >
      {icon !== undefined && (
        <span className='button__icon'>
          {/* <img src={icon} alt={label} />*/}
          {icon}
        </span>
      )}
      {label !== undefined && <span className='button__label'>{label}</span>}
    </button>
  )
}

export default Button

import React from "react"

const Main = ({ children }) => {
  return (
    <main className='main-content' style={{ height: window.innerHeight }}>
      {children}
    </main>
  )
}

export default Main

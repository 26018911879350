import React from "react"

const PauseIcon = () => {
  return (
    <svg
      className='icon icon--pause'
      width='17'
      height='24'
      viewBox='0 0 17 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='4' height='24' rx='2' />
      <rect x='13' width='4' height='24' rx='2' />
    </svg>
  )
}

export default PauseIcon

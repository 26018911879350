import React from "react"
import { useStateValue } from "../state/state"
import Actions from "./Actions"
import Button from "./Button"
import NextExercise from "./NextExercise"
import CurrentExercise from "./CurrentExercise"
import Timer from "./Timer"
import PlayIcon from "../icons/PlayIcon"
import PauseIcon from "../icons/PauseIcon"
import NextIcon from "../icons/ArrowRightIcon"
import PrevIcon from "../icons/ArrowLeftIcon"
import { useHotkeys } from "react-hotkeys-hook"
import ReactTooltip from "react-tooltip"

const Exercise = () => {
  const [
    { currentStep, nextExercise, isStart, isPaused, isSetupTime, isLastStep },
    dispatch
  ] = useStateValue()
  const exerciseIsHidden = isStart

  useHotkeys(
    "Space",
    event => {
      event.preventDefault()
      if (isPaused) {
        dispatch({
          type: "startTimer"
        })
      } else {
        dispatch({
          type: "pauseTimer"
        })
      }
    },
    [isPaused]
  )
  useHotkeys(
    "left",
    () => {
      dispatch({
        type: "setPreviousExercise"
      })
    },
    []
  )
  useHotkeys(
    "right",
    () => {
      dispatch({
        type: "setNextExercise"
      })
    },
    []
  )

  const handleOnClickStart = () => {
    dispatch({
      type: "startTimer"
    })
  }
  const handleOnClickPause = () => {
    dispatch({
      type: "pauseTimer"
    })
  }
  const handleOnClickReset = () => {
    dispatch({
      type: "resetWorkout"
    })
  }

  const handleNextExercise = () => {
    dispatch({
      type: "setNextExercise"
    })
  }
  const handlePreviousExercise = () => {
    dispatch({
      type: "setPreviousExercise"
    })
  }

  return (
    <div
      className={`layout layout--exercise`}
      style={{ display: exerciseIsHidden ? "none" : "" }}
    >
      <div className='exercise-panel'>
        <CurrentExercise exerciseName={currentStep.name} />
        <Timer duration={currentStep.duration} />
        <NextExercise exercise={nextExercise} />
      </div>
      <Actions className={"actions--bottom"}>
        <Button
          dataTip
          dataFor='previous-exercise'
          type={"secondary"}
          icon={<PrevIcon />}
          onClick={handlePreviousExercise}
          disabled={isSetupTime}
        />
        {isPaused ? (
          <Button
            dataTip
            dataFor='play'
            type={"main"}
            icon={<PlayIcon />}
            onClick={handleOnClickStart}
          />
        ) : (
          <Button
            dataTip
            dataFor='pause'
            type={"main"}
            icon={<PauseIcon />}
            onClick={handleOnClickPause}
          />
        )}
        <Button
          dataTip
          dataFor='next-exercise'
          type={"secondary"}
          icon={<NextIcon />}
          onClick={handleNextExercise}
          disabled={isLastStep}
        />
      </Actions>
      <Actions className={"actions--top"}>
        <Button type={"text"} label='Restart' onClick={handleOnClickReset} />
      </Actions>
      <ReactTooltip
        id='previous-exercise'
        className='tooltip'
        delayShow={0}
        effect='solid'
      >
        <span>Previous Exercise</span>
      </ReactTooltip>
      <ReactTooltip
        id='next-exercise'
        className='tooltip'
        delayShow={0}
        effect='solid'
      >
        <span>Next Exercise</span>
      </ReactTooltip>
      <ReactTooltip id='play' className='tooltip' delayShow={0} effect='solid'>
        <span>Start / Pause</span>
      </ReactTooltip>
      <ReactTooltip id='pause' className='tooltip' delayShow={0} effect='solid'>
        <span>Pause</span>
      </ReactTooltip>
    </div>
  )
}

export default Exercise
